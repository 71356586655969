import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import { Link } from 'gatsby';
import { Rubric } from 'styles/typography';

const PostCardContainer = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  color: #fff;
  min-height: 40rem;

  h4 {
    margin-top: 0.8rem;
    text-shadow: 0.2rem 0.2rem 0.8rem rgba(0,0,0,0.12);
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    &:hover {
      > div:last-child {
        transform: translateY(-1.6rem);
      }
    }
  }
`;

const PostCardContent = styled.div`
  position: relative;
  z-index: 10;
`;

const PostCardImage = styled.div`
  position: absolute;
  display: block;
  width: 82%;
  max-width: 33.6rem;
  top: 6.8rem;
  left: 12%;
  will-change: transform;
  transition: transform 0.22s ease-out;
  
  &::before {
    content: '';
    display: block;
    padding-top: 75%;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0,0,0,0.32), rgba(0,0,0,0.22) 40%, rgba(0,0,0,0) 100%);
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    top: 8.8rem;
  }
`;

class PostCard extends Component {
  render() {
    const {destination, type, title, image} = this.props;

    return (
      <PostCardContainer to={destination}>
        {(type || title) && (
          <PostCardContent>
            {type && <Rubric>{type.name}</Rubric>}
            {title && <h4>{title[0].text}</h4>}
          </PostCardContent>
        )}

        {image &&
        <PostCardImage>
          <img
            src={image.url}
            alt={image.alt}
          />
        </PostCardImage>
        }
      </PostCardContainer>
    );
  }
}

export default PostCard;
