import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Link } from 'gatsby';

const CardContainer = styled(Link)`
  color: inherit;
    
  h4 {
    font-weight: 400;
    transition: color 0.12s ease-in-out;
  }
  
  span {
    font-size: 1.4rem;
    transition: color 0.12s ease-in-out;
  }
  
  &:hover {
    h4, span {
      color: ${colors.red600};
    }
    
    div::after {
      transform: scaleX(1);
    }
    
    img {
      transform: scale(1.05);
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    span {
      font-size: 1.8rem;
    }
  }
`;

const CardImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 0.8rem;
  overflow: hidden;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    transition: transform 0.18s ease-in-out;
    transform-origin: center center;
  }
  
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.8rem;
    background-color: ${colors.red600};
    transform: scaleX(0);
    transform-origin: center left;
    transition: transform 0.12s ease-out;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-bottom: 2.4rem;
  }
`;

class LeaderCard extends Component {
  render() {
    const {destination, image, name, title} = this.props;

    return (
      <CardContainer to={destination}>
        {image && (
          <CardImage>
            <img src={image.url} alt={image.alt || name}/>
          </CardImage>
        )}

        <div>
          {name && <h4>{name}</h4>}
          {title && <span>{title}</span>}
        </div>

      </CardContainer>
    );
  }
}

export default LeaderCard;
