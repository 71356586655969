import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import Content from 'components/_ui/Content';
import { Parallax } from "react-scroll-parallax";
import Fade from 'react-reveal/Fade';

const HeaderContainer = styled.header`
  padding: 4rem 0 8rem;
  
  .parallax-outer {
    position: relative;
    z-index: -1;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 10.4rem 0 12rem;
    
    &.AboutHeader--specialty {
      padding-bottom: 8rem;
    }
  }
`;

const HeaderInner = styled(Inner)`
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {}
`;

const HeaderTitle = styled.div`
  position: relative;
  z-index: -1;
  pointer-events: none;
  left: -2.6rem;
  margin-top: 2.4rem;
  pointer-events: none;
  
  h1 {
    color: transparent;
    font-size: 18vw;
    font-weight: 600;
    line-height: 1;
    -webkit-text-stroke: 1px ${colors.red600};
    text-stroke: 1px ${colors.red600};
    white-space: nowrap;
  }
  
  .AboutHeader--long & {
    h1 {
      font-size: 16vw;
    }
  }
  
  .AboutHeader--specialty & {
    top: 0 !important;
  
    h1 {
      font-size: 14vw;
      white-space: initial;
    }
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    left: -4rem;
  }

  @media(min-width: ${dimensions.desktopUp}px) {
    h1 {}
    
    .AboutHeader--long & {
      h1 {
        font-size: 14.4vw;
      }
    }
  } 
  
  @media(min-width: ${dimensions.desktopLargeUp}px) {
    h1 {
      font-size: 26rem;
    }
    
    .AboutHeader--long & {
      h1 {
        font-size: 21.6rem;
      }
    }
  }
`;

const HeaderContent = styled.div`  
  h1, h2, h3 {
    margin-bottom: 2.4rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-left: 41.6667%;
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    padding-right: 10.4rem;
  }
`;

class AboutHeader extends Component {
  render() {
    return (
      <HeaderContainer className={`${this.props.longTitle ? "AboutHeader--long" : ''} ${this.props.variant ? `AboutHeader--${this.props.variant}` : ''}`}>
        <HeaderInner>
          <HeaderContent>
            <Fade bottom distance="2rem">
              {this.props.heading && <h2>{this.props.heading[0].text}</h2>}
              {this.props.content && <Content content={this.props.content}/>}
            </Fade>
          </HeaderContent>

          <Parallax y={this.props.parallax}>
            <HeaderTitle>
              <h1>{this.props.title}</h1>
            </HeaderTitle>
          </Parallax>
        </HeaderInner>
      </HeaderContainer>
    );
  }
}

export default AboutHeader;
