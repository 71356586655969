import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';

const GridContainer = styled.ul`
  width: 100%;
  position: relative;
  
  & + * {
    margin-top: 8rem;
  }
  
  &:last-of-type {
    margin-bottom: 8rem;
  }
  
  li {
    display: block;
    
    & + * {
      margin-top: 4rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2.5vw);
    left: -1.25vw;
    
    & + * {
      margin-top: 12rem;
    }
    
    &:last-of-type {
      margin-bottom: 12rem;
    }
    
    li {
      flex: 1 1 calc(50% - 2.5vw);
      margin-left: 1.25vw;
      margin-right: 1.25vw;
    
      & + * {
        margin-top: 0;
      }
      
      &:nth-of-type(n+3) {
        margin-top: 4rem;
      }
    }
  }
  
  @media(min-width: ${dimensions.desktopTransitionUp}px) {
    display: flex;
    width: calc(100% + 2.5vw);
    left: -1.25vw;
    
    li {
      flex: 0 1 calc(33.3334% - 5vw);
      margin-left: 2.5vw;
      margin-right: 2.5vw;
    
      & + * {
        margin-top: 0;
      }
      
      &:nth-of-type(n+3) {
        margin-top: 0;
      }
      
      &:nth-of-type(n+4) {
        margin-top: 5.6rem;
      }
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    width: calc(100% + 7.2rem);
    left: -3.6rem;
      
    li {
      flex: 0 1 calc(33.3334% - 7.2rem);
      margin-left: 3.6rem;
      margin-right: 3.6rem;
    }
  }
`;

class LeadersGrid extends Component {
  render() {
    return (
      <GridContainer>
        {this.props.children}
      </GridContainer>
    );
  }
}

export default LeadersGrid;
