import React, { Component } from "react";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import { Inner } from "styles/structure";
import TrapdoorGrid from 'components/_grid/Trapdoor';
import Trapdoor from 'components/_card/Trapdoor';
import RelatedStudy from "components/_card/RelatedStudy";
import PostCard from 'components/_card/Post';
import InsightCard from 'components/_card/Insight';

const RelatedContainer = styled.div`
  background-color: #000;
  padding-bottom: 2.4rem;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-bottom: 4.8rem;
  }
`;

const RelatedHeader = styled.h4`
  color: white;
  text-align: center;
  padding-top: 6.4rem;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-top: 8rem;
  }
`;

class Related extends Component {
  render() {
    return (
      <RelatedContainer>
        {this.props.heading && <RelatedHeader>{this.props.heading}</RelatedHeader>}

        {this.props.cards && this.props.cards.length > 0 && (
          <Inner>
            <TrapdoorGrid>
              {this.props.cards.map((card, i) => (
                <li key={i}>
                  {this.props.type === 'trapdoor' && (
                    <Trapdoor
                      destination={card.destination}
                      image={card.image}
                      name={card.name}
                      description={card.description}
                    />
                  )}

                  {this.props.type === 'specialty' && (
                    <Trapdoor
                      destination={`/specialty/${card.specialty._meta.uid}`}
                      image={card.specialty.feature_image}
                      name={card.specialty.name && card.specialty.name[0].text}
                      description={card.specialty.short_description}
                    />
                  )}

                  {this.props.type === 'study' && (
                    <RelatedStudy
                      uid={card.study._meta.uid}
                      image={card.study.feature_image}
                      name={card.study.client_name}
                      area={card.study.area}
                      tagline={card.study.tagline}
                    />
                  )}

                  {this.props.type === 'post' && (
                    <PostCard
                      destination={`/post/${card.post._meta.uid}`}
                      image={card.post.feature_image}
                      title={card.post.title}
                      type={card.post.type}
                    />
                  )}

                  {this.props.type === 'insight' && (
                    <InsightCard
                      destination={`/insight/${card.insight._meta.uid}`}
                      image={card.insight.feature_image}
                      title={card.insight.title}
                      category={card.insight.category}
                    />
                  )}
                </li>
              ))}
            </TrapdoorGrid>
          </Inner>
        )}
      </RelatedContainer>
    );
  }
}

export default Related;
