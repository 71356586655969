import React from 'react';
import { graphql } from 'gatsby'
import { Inner } from 'styles/structure';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import AboutHeader from 'components/_page/about/Header';
import LeadersGrid from 'components/_grid/Leaders';
import LeaderCard from 'components/_card/Leader';
import Related from 'components/Related';

const RenderBody = ({ leadership, about, history }) => (
  <>
    <AboutHeader
      title='Leadership'
      longTitle={true}
      heading={leadership.introduction_heading}
      content={leadership.introduction_content}
      parallax={[-40, 100]}
    />

    <Inner>
      <LeadersGrid>
        {leadership.leaders.map((leader, i) => (
          <li key={i}>
            <LeaderCard
              destination={`/bio/${leader.person._meta.uid}`}
              image={leader.person.image}
              name={leader.person.name}
              title={leader.person.title}
            />
          </li>
        ))}
      </LeadersGrid>
    </Inner>

    <Related
      heading='Learn more about us'
      type='trapdoor'
      cards={[
        {
          destination: '/about',
          image: about.feature_image,
          name: 'About',
          description: about.short_description,
        },
        {
          destination: '/history',
          image: history.feature_image,
          name: 'History',
          description: history.short_description,
        },
      ]}
    />
  </>
);

export default ({ data }) => {
  //Required check for no data being returned
  const leadership = data.prismic.allLeaderships.edges[0].node;
  const about = data.prismic.allAbouts.edges[0].node;
  const history = data.prismic.allHistorys.edges[0].node;

  if (!leadership) return null;

  return (
    <Layout>
      <SEO
        title={leadership.social_title || 'Leadership'}
        description={leadership.social_description ? leadership.social_description : null}
        image={leadership.social_image ? leadership.social_image.url : null}
      />

      <RenderBody leadership={leadership} about={about} history={history} />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allLeaderships {
        edges {
          node {
            introduction_heading
            introduction_content
            leaders {
              person {
                ... on PRISMIC_Person {
                  name
                  title
                  _meta {
                    uid
                  }
                  image
                }
              }
            }
            social_title
            social_description
            social_image
          }
        }
      }
      allAbouts {
        edges {
          node {
            feature_image
            short_description
          }
        }
      }
      allHistorys {
        edges {
          node {
            feature_image
            short_description  
          }
        }
      }
      allSocials {
        edges {
          node {
            title
            description
            image
          }
        }
      }
    }
  }
`
