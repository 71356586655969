import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Link } from 'gatsby';
import { Rubric } from 'styles/typography';

const CardContainer = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  
  overflow: hidden;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
`;

const CardImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.24s ease-out;
`;

const CardContent = styled.div`
  min-height: 40rem;
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.grey900};
    opacity: 0.32;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: center;
    min-height: 49.6rem;
  }
`;

const CardText = styled.div`
  padding: 8.533vw;
  color: #fff;
  isolation: isolate;

  h2 {
    font-weight: 600;
    margin-top: 0.8rem;
  }

  h6 {
    font-weight: 600;
    margin-top: 2.4rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 6.4rem 5vw;

    h2 {
      margin-top: 1.6rem;
    }

    h6 {
      margin-top: 4.8rem;
    }
  }
`;

const Area = styled(Rubric)`
  display: block;
  color: #fff;
`;

class RelatedStudy extends Component {
  render() {
    const { uid, image, name, area, tagline } = this.props;

    return (
      <CardContainer to={`/study/${uid}`}>
        {image && (
          <CardImage
            src={image.url}
            alt={image.alt}
          />
        )}

        {(name || tagline) && (
          <CardContent>
            <CardText>
              {area && <Area>{area.name}</Area>}
              {name && <h6>{name[0].text}</h6>}
              {tagline && <h2>{tagline[0].text}</h2>}
            </CardText>
          </CardContent>
        )}
      </CardContainer>
    );
  }
}

export default RelatedStudy;
