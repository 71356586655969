import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Link } from 'gatsby';

const TrapdoorContainer = styled.div`
  position: relative;
  display: block;
  width: 100%; 
  overflow: hidden;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    &:hover {      
      img {
        transform: scale(1.05);
      }
    }
  }
}
`;

const TrapdoorImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.24s ease-out; 
`;

const TrapdoorContent = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 4.8rem 5.6rem;
  min-height: 32rem;
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.grey900};
    opacity: 0.32;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 8.8rem 5.6rem;
  }
`;

const TrapdoorText = styled.div`
  position: relative;
  color: #fff;
  z-index: 10;
  
  h2 {
    margin-bottom: 0.4rem;
  } 
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    h2 {
      margin-bottom: 0.8rem;
    }
  }
`;


class Trapdoor extends Component {
  render() {
    const { destination, image, name, description} = this.props;

    return (
      <Link to={destination}>
        <TrapdoorContainer>
          {image && (
            <TrapdoorImage
              src={image.url}
              alt={image.alt || name}
            />
          )}

          {(name || description) && (
            <TrapdoorContent>
              <TrapdoorText>
                {name && <h2>{name}</h2>}
                {description && <p>{description}</p>}
              </TrapdoorText>
            </TrapdoorContent>
          )}
        </TrapdoorContainer>
      </Link>
    );
  }
}

export default Trapdoor;
